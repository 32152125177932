import il8n from '@/assets/language/index.js';
const { t } = il8n.global;

export const tableOption1 = {
    border: true,
    stripe: true,
    menuAlign: 'center',
    searchMenuSpan: 6,
    align: 'center',
    viewBtn: false,
    height: 'auto',
    columnBtn: false,
    addBtn: false,
    editBtn: false,
    delBtn: false,
    refreshBtn: false,
    menu: false,
    column: [{
        label: t('use.text2'),
        prop: 'tenantName',
    }, {
        label: t('use.text3'),
        prop: 'appName',
    }, {
        label: t('use.text8'),
        prop: 'requestNum',
    }, 
    {
        width: 160,
        label: t('use.text9'),
        prop: 'maxTime',
    }, 
    {
        width: 160,
        label: t('use.text10'),
        prop: 'minTime',
    },{
        width: 120,
        label: t("button.operation"),
        prop: 'caozuo',
        placeholder: t("inputTit.shuru"),
        fixed:'right',
      }]
}