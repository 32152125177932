<template>
    <div>
        <fromSearch :object_='object_' @searchFun='getList'/>
        <avue-crud :option="tableOption" :data="page.list" :page="page" :table-loading="listLoading"
            @size-change="sizeChange"
            @current-change="currentChange">
            <template #caozuo="scope">
                <el-button v-if="roleManager_btn_look" text type="primary"
                    size="small"    @click="lookFun(scope.row)">{{$t('button.look')}} </el-button>
            </template>
            <template #maxTime="scope">
                {{turnTimeFun(scope.row.minTime)}}
            </template>
            <template #minTime="scope">
                {{turnTimeFun(scope.row.maxTime)}}
            </template>
        </avue-crud>
        <el-dialog v-model="dialogTableVisible" :title="$t('use.text1')">
            <el-descriptions>
                <el-descriptions-item :label="$t('use.text2')">{{parms_.tenantName}}</el-descriptions-item>
                <el-descriptions-item :label="$t('use.text3')">{{parms_.appName}}</el-descriptions-item>
                <el-descriptions-item :label="$t('use.text8')">{{parms_.requestNum}}</el-descriptions-item>
            </el-descriptions>
            <el-table :data="listData" height="55vh">
                <!-- <el-table-column property="tenantName" :label="$t('use.text2')" width="130" />
                <el-table-column property="appName" :label="$t('use.text3')" width="130" /> -->
                <el-table-column property="requestUrl" :label="$t('use.name1')" />
                <el-table-column property="fromIp" :label="$t('use.text6')"  width="130"/>
                <el-table-column property="requestTime" :label="$t('use.name2')" width="160"/>
                <el-table-column property="responseTime" :label="$t('use.name3')"  width="160"/>
            </el-table>
            <el-pagination v-model:current-page="data_.currentPage1"
                :page-size="data_.pageSize"
                layout="total, prev, pager, next"
                :total="data_.pageTotal"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange" />
        </el-dialog>
    </div>
</template>
<script setup>
    import Api_ from '@/api/index'
    import { ref,reactive } from 'vue'
    import { tableOption1 } from "@/const/crud/platform/use";
    
    import fromSearch from '@/components/fromSearch.vue'
    import { use1 } from '@/const/from/platform/use'
    const object_ = ref(use1);
    import { turnTimeFun } from '@/utils/util.js'   

    import mixins from '@/views/mixins/page'
    let pageObj = mixins(['getUseList'])
    const {page,listLoading,sizeChange,currentChange,getList} = pageObj
    
    const dialogTableVisible = ref(false)
    const listData = ref([])
    const tableOption = ref({});tableOption.value = tableOption1
    const roleManager_btn_look = ref(true)
    const data_ = reactive({
        pageSize:10,
        pageTotal:0,
        currentPage1: 1,
        merchantId: ''
    })
    const parms_ = reactive({
        tenantName:'',
        appName:0,
        requestNum: 0
    })

    const lookFun = (e)=>{
        data_.merchantId = e.merchantId
        dialogTableVisible.value = true
        parms_.tenantName = e.tenantName
        parms_.appName = e.appName
        parms_.requestNum = e.requestNum
        getListDetail()
    }
    const getListDetail = ()=>{
        Api_.getUseListDetail({current: data_.currentPage1,size: data_.pageSize,merchantId:data_.merchantId}).then(res=>{
            if(res.code === 0){
                listData.value = res.data.records
                data_.pageTotal = res.data.total
            }
        })
    }
    const handleSizeChange = (e)=>{
        data_.currentPage1 = 1
        data_.pageSize = e
        getListDetail()
    }
    const handleCurrentChange = (e)=>{
        data_.currentPage1 = e
        getListDetail()
    }
    getList(1)
</script>

<style lang="scss" scoped>

    :deep(.el-dialog__header){
        margin-bottom: 0;
    }
</style>